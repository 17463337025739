import React, {useEffect} from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import { Inner } from "styles/structure";
import colors from "styles/colors";
import Seo from 'components/Seo';
import Layout from 'components/Layout';
import Hero from 'components/Hero';
import ContentBlock from 'components/ContentBlock';
import TrapdoorGrid from 'components/_grid/Trapdoor';
import TrapdoorCard from 'components/_card/Trapdoor';
import { document } from 'browser-monads';
import { withPreview } from 'gatsby-source-prismic';

const DonateForm = styled.div`
  margin: 8rem 0;
  
  #give-lively-widget {
    * {
      font-family: "Basis Grotesque", sans-serif !important;
    }
    
    .gl-widget__btn,
    .gl-widget__donation-input-field,
    .gl-widget__input-group {
      border-radius: 0 !important;
      font-size: 2rem !important;
    }

    .gl-widget__btn--outline-primary:hover:not(.gl-widget--selected-btn),
    .gl-widget__btn--outline-primary:active:not(.gl-widget--selected-btn), 
    .gl-widget__btn--outline-primary:focus:not(.gl-widget--selected-btn) {
      border-color: ${colors.orange500} !important;
      background: #fff !important;
      color: ${colors.orange500} !important;
    }
    
    .gl-widget__frequency-buttons,
    .gl-widget__suggested-amount-button {
      height: 5.6rem !important;
      border-width: 2px !important;
      font-weight: 400 !important;
    }

    .gl-widget__input-group {
      position: relative;
      
      &.show-other {
        &::before {
          content: 'Other';
          color: #999;
          position: absolute;
          top: 50%;
          left: 40px;
          z-index: 100;
          transform: translateY(-50%);
          pointer-events: none;
        }
      }
    }
    
    .gl-widget__donation-input-field {
      height: 7.2rem !important;
      font-weight: 400 !important;
      border: 2px solid #B2B3B5 !important;

      &:focus {
        border-color: ${colors.orange500} !important;
        background: #fff !important;
      }
    }
    
    .gl-widget__input-group {
      border-width: 0 !important;
    }
    
    .gl-widget--selected-btn {
      border-color: ${colors.teal600} !important;
      background: ${colors.teal300} !important;
      color: ${colors.teal600} !important;
      padding: 1px !important;
    }

    .gl-widget__currency {
      font-size: 2rem !important;
      top: 13px !important;
    }
    
    .gl-widget__btn--primary {
      background: ${colors.teal600} !important;
      text-transform: lowercase !important;
      font-size: 2.4rem !important;
      height: 7.2rem !important;
      
      &::first-letter {
        text-transform: uppercase;
      }
      
      &:hover {
        color: #fff !important;
        background: ${colors.yellow500} !important;
      }
      
      &:active {
        background: ${colors.yellow400} !important;
      }
    }
    
    .gl-widget__other-payment-method-link {
      color: ${colors.teal600} !important;
      transition: color 0.12s ease-in-out !important;
      
      &:hover {
        color: ${colors.orange500} !important;
      }

      &:active {
        color: ${colors.yellow400} !important;
      }
    }
  }
`;

const FormInner = styled.div`
  background-color: #eee;
  padding: 4rem 3.2rem;
`;

const FormContent = styled.div`
  position: relative;
  width: 100%;
  max-width: 84.8rem;
  margin: 0 auto;
`;

function waitForElement(querySelector, timeout=0){
  const startTime = new Date().getTime();
  return new Promise((resolve, reject)=>{
    const timer = setInterval(()=>{
      const now = new Date().getTime();
      if(document.querySelector(querySelector)){
        clearInterval(timer);
        resolve();
      }else if(timeout && now - startTime >= timeout){
        clearInterval(timer);
        reject();
      }
    }, 100);
  });
}

const RenderBody = ({ donate }) => {
  waitForElement(".gl-widget__payment-request-button-container").then(function() {
    const otherPaymentLink = document.querySelector('.gl-widget__other-payment-method-link');
    const customAmountWrapper = document.querySelector('.gl-widget__input-group');
    const customAmountInput = document.querySelector('.gl-widget__donation-input-field');

    // prevents default Gatsby handling for 'or choose another payment method' <a> link
    otherPaymentLink.addEventListener('click', function(e) {
      e.preventDefault();
    });

    customAmountWrapper.classList.add('show-other');

    customAmountInput.addEventListener('focus', function(e) {
      customAmountWrapper.classList.remove('show-other');
    })
  }).catch(() => {
    console.log("GL widget button container did not load.");
  });

  return (
    <>
      <Hero
        heading={donate.hero_heading}
        text={donate.hero_text}
        background={donate.hero_background}
      />

      {donate.introduction && <ContentBlock content={donate.introduction}/>}

      <DonateForm>
        <FormInner>
          <FormContent>
            <div id="give-lively-widget" className="gl-simple-donation-widget"></div>
          </FormContent>
        </FormInner>
      </DonateForm>

      <Inner>
        <TrapdoorGrid>
          {donate.trapdoors.slice(0,2).map((card, i) => (
            <li>
              <TrapdoorCard
                key={i}
                heading={card.heading}
                content={card.content}
                buttonText={card.button_text}
                destination={card.destination}
              />
            </li>
          ))}
        </TrapdoorGrid>
      </Inner>
    </>
  )
}

const DonatePage = ({ data }) => {
  //Required check for no data being returned
  const donate = data.allPrismicDonate.edges[0].node.data;

  useEffect(() => {
    let gl = document.createElement('script');
    gl.src = 'https://secure.givelively.org/widgets/simple_donation/leading-edge-alliance-inc.js?show_suggested_amount_buttons=true&show_in_honor_of=false&address_required=false&has_required_custom_question=null&suggested_donation_amounts[]=100&suggested_donation_amounts[]=500&suggested_donation_amounts[]=1000&suggested_donation_amounts[]=1800';
    document.getElementsByTagName('head')[0].appendChild(gl);
  }, [])

  return (
    <Layout>
      <Seo
        title={donate.social_title || 'Consider Donating to Leading Edge Today'}
        description={donate.social_description}
        image={donate.social_image}
      />

      <RenderBody donate={donate} />
    </Layout>
  );
};

export const query = graphql`
  {
    allPrismicDonate {
      edges {
        node {
          data {
            hero_heading {
              raw
            }
            hero_background {
              url
              alt
            }
            introduction {
              raw
            }
            trapdoors {
              heading {
                raw
              }
              content {
                raw
              }
              button_text
              destination
            }
            social_title
            social_description
            social_image {
              url
            }
          }
        }
      }
    }
  }
`;

export default withPreview(DonatePage);