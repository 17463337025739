import React from 'react';
import Content from 'components/_ui/Content';
import styled from '@emotion/styled'
import colors from "styles/colors";
import dimensions from 'styles/dimensions';
import { Inner } from 'styles/structure';

const ContentBlockContainer = styled.div`
  position: relative;
  
  & + * {
    margin-top: 6.4rem;
  } 
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    & + * {
      margin-top: 8rem;
    }
  }
`;

const ContentBlockWrap = styled.div`
  position: relative;
  width: 100%;
  max-width: 84.8rem;
  margin: 0 auto;
  
  .ContentBlock--applications & {
    border-top: 1px solid ${colors.grey400};
    padding-top: 3.2rem;
  }
  
  @media(min-width: ${dimensions.tabletLandscapeUp}px) {
    .ContentBlock--applications & {
      padding-top: 4.8rem;
    }
  }
`;

const ContentBlock = ({ content, image, variant }) => {
  return (
    <ContentBlockContainer className={variant && `ContentBlock--${variant}`}>
      <Inner>
        <ContentBlockWrap>
          {content && <Content content={content.raw}/>}
        </ContentBlockWrap>
      </Inner>
    </ContentBlockContainer>
  );
};

export default ContentBlock;
